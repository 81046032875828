import React from "react";

import { ReactComponent as LoaderIcon } from "assets/images/loader.svg";

const Loader = (props) => {
  return (
    <LoaderIcon className="loader-icon" width={props.size} height={props.size} />
  )
}

Loader.defaultProps = {
  size: 120,
}

export default Loader;
