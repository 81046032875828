import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";

import CustomerHistory from "./CustomerHistory";
import CustomerInformation from "./CustomerInformation";
import PartExchange from "./PartExchange";
import PreQualifiedQuotes from "./PreQualifiedQuotes";
import SubmittedApplications from "./SubmittedApplications";
import VehicleInformation from "./VehicleInformation";

const ApplicationInformationForm = (props) => {
  const handleSortBy = (e, value) => {
    e.stopPropagation();
    props.onSortBy(value);
  }

  return (
    <Accordion
      activeKey={props.activeTab}
      onSelect={eventKey => props.setActiveTab(eventKey)}
      className="spaced"
    >
      { props.application?.applicationGuid && (
        <>
          <Accordion.Item eventKey="prequalified">
            <Accordion.Header className="quotes-accordion">
              <div className="quotes-accordion-header">
                <div>
                  Pre-approved quotes
                </div>
                { props.activeTab === "prequalified" && (
                  <div className="quotes-sort-by d-flex">
                    <div>Sort by:</div>
                    <button onClick={e => handleSortBy(e, "payments")} className={`filter ${props.sortBy === "payments" && "active"}`}>
                      Monthly price
                    </button>
                    <button onClick={e => handleSortBy(e, "apr")} className={`filter ${props.sortBy === "apr" && "active"}`}>
                      APR
                    </button>
                    <button onClick={e => handleSortBy(e, "totalInterest")} className={`filter ${props.sortBy === "totalInterest" && "active"}`}>
                      Total interest cost
                    </button>
                  </div>
                )}
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <PreQualifiedQuotes
                application={props.application}
                quotes={props.quotes}
                quoteChanged={props?.quoteChanged}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="submitted">
            <Accordion.Header>Activity</Accordion.Header>
            <Accordion.Body>
              <SubmittedApplications application={props.application} />
            </Accordion.Body>
          </Accordion.Item>
        </>
      )}
      <Accordion.Item eventKey="customer">
        <Accordion.Header>Customer information</Accordion.Header>
        <Accordion.Body>
          <CustomerInformation
            application={props.application}
            schema={props.schema}
            onUpdate={props.onUpdate}
            setActiveTab={props.setActiveTab}
          />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="vehicle">
        <Accordion.Header>Vehicle information</Accordion.Header>
        <Accordion.Body>
          <VehicleInformation
            application={props.application}
            onUpdate={props.onUpdate}
            setApplication={props.setApplication}
            setActiveTab={props.setActiveTab}
          />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="partexchange">
        <Accordion.Header>Part exchange information</Accordion.Header>
        <Accordion.Body>
          <PartExchange
            application={props.application}
            onUpdate={props.onUpdate}
            setActiveTab={props.setActiveTab}
          />
        </Accordion.Body>
      </Accordion.Item>

      { props.application?.applicationGuid && (
        <Accordion.Item eventKey="history">
          <Accordion.Header>Customer history</Accordion.Header>
          <Accordion.Body>
            <CustomerHistory
              application={props.application}
            />
          </Accordion.Body>
        </Accordion.Item>
      )}
    </Accordion>
  );
}

export default ApplicationInformationForm;
