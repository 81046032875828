import React, { useEffect, useState } from "react";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { Col, Form, Row } from "react-bootstrap";
import { Modal, Spinner } from "react-bootstrap";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const PolicyModal = (props) => {
  const [ formData, setFormData ] = useState({});
  const [ loading, setLoading ] = useState(false);

  useEffect(() => {
    if (props.policy) {
      let editPolicy = { ...props.policy };

      if (editPolicy?.terms) {
        let termsContent = ContentState.createFromBlockArray(htmlToDraft(props?.policy?.terms));
        
        editPolicy.terms = EditorState.createWithContent(termsContent);
      } else {
        editPolicy.terms = EditorState.createEmpty();
      }

      setFormData(editPolicy);
    } else {
      setFormData({});
    }
  }, [props.policy]);

  const handleConfirm = () => {
    setLoading(true);

    let localFormData = { ...formData };
    localFormData.terms = draftToHtml(convertToRaw(formData.terms.getCurrentContent()));
    props.onConfirm(localFormData);
    setLoading(false);
  }

  const handleTextChange = (editorState) => {
    setFormData({
      ...formData,
      terms: editorState,
    });
  }

  const handleFileUpload = (e) => {
    if (e.target.files) {
      setFormData({
        ...formData,
        [e.target.name]: e.target.files[0],
      });
    }
  }

  return (
    <Modal show={props.show} onHide={props.onCancel} className="confirm-modal policy-form-modal">
      <Modal.Header closeButton>
        <Modal.Title>Manage { props?.policy?.name ?? "Policy" }</Modal.Title>
      </Modal.Header>
      <Modal.Body className="policy-form form-container">
        <Row>
          <Col xs={12} className="form-item">
            <label>Policy Text</label>
            <div>
              <Editor
                editorState={formData.terms}
                onEditorStateChange={handleTextChange}
              />
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="grey"
          onClick={props.onCancel}
        >
          Cancel
        </button>
        <button
          className="dark"
          onClick={handleConfirm}
        >
          { loading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            <>Confirm</>
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default PolicyModal;
