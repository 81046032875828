import React, { useEffect, useState } from "react";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import Notifications from "./Notifications";

import { ReactComponent as CloseIcon } from "assets/images/menu/close.svg";
import { ReactComponent as CustomersIcon } from "assets/images/menu/customers.svg";
import { ReactComponent as DashboardIcon } from "assets/images/menu/dashboard.svg";
import { ReactComponent as DealersIcon } from "assets/images/menu/dealers.svg";
import { ReactComponent as LendersIcon } from "assets/images/menu/lenders.svg";
import { ReactComponent as LogoSmallIcon } from "assets/images/menu/logo-small.svg";
import { ReactComponent as NotificationsIcon } from "assets/images/menu/notifications.svg";
import { ReactComponent as PoliciesIcon } from "assets/images/menu/policies.svg";
import { ReactComponent as QuoteIcon } from "assets/images/menu/quote.svg";
import logoIcon from "assets/images/logo-white.png";

const Sidebar = (props) => {
  const location = useLocation();

  const [showNotificationsMenu, setShowNotificationsMenu] = useState(false);

  const { sidebarOpen } = props;

  const isAdmin = useSelector(state => state?.auth?.isAdmin);
  const notifications = useSelector(state => state?.auth?.notifications);

  const [ total, setTotal ] = useState(0);

  useEffect(() => {
    if (notifications) {
      setTotal(Object.values(notifications).reduce((prev, next) => prev + next));
    } else {
      setTotal(0);
    }
  }, [notifications]);

  const showNotifications = (e) => {
    e.preventDefault();
    setShowNotificationsMenu(!showNotificationsMenu);
  };

  return (
    <div className="user-layout-sidebar">
      <div className="sidebar-logo">
        <img src={logoIcon} className="expanded" />
        <LogoSmallIcon className="closed" />
      </div>

      <ul>
        {!sidebarOpen ? (
          <>
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Dashboard</Tooltip>}
              delay={{ show: 250, hide: 400 }}
            >
              <li className={location.pathname === "/" ? "activeMenu" : ""}>
                <Link to="/">
                  <DashboardIcon />
                  <span>Dashboard</span>
                </Link>
              </li>
            </OverlayTrigger>

            { isAdmin ? (
              <>
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>Dealers</Tooltip>}
                  delay={{ show: 250, hide: 400 }}
                >
                  <li className={location.pathname === "/dealers" ? "activeMenu" : ""}>
                    <Link to="/dealers">
                      <DealersIcon />
                      <span>Dealers</span>
                    </Link>
                  </li>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>Policies</Tooltip>}
                  delay={{ show: 250, hide: 400 }}
                > 
                  <li className={location.pathname === "/policies" ? "activeMenu" : ""}>
                    <Link to="/policies">
                      <PoliciesIcon />
                      <span>Policies</span>
                    </Link>
                  </li>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>Compliance Questions</Tooltip>}
                  delay={{ show: 250, hide: 400 }}
                >
                  <li className={location.pathname === "/compliance" ? "activeMenu" : ""}>
                    <Link to="/policies">
                      <LendersIcon />
                      <span>Compliance</span>
                    </Link>
                  </li>
                </OverlayTrigger>
              </>
            ) : (
              <>
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>Lenders</Tooltip>}
                  delay={{ show: 250, hide: 400 }}
                >
                  <li
                    className={location.pathname === "/lenders" ? "activeMenu" : ""}
                  >
                    {" "}
                    <Link to="/lenders">
                      <LendersIcon />
                      <span>Lenders</span>
                    </Link>
                  </li>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>Customers</Tooltip>}
                  delay={{ show: 250, hide: 400 }}
                >
                  <li
                    className={
                      location.pathname === "/customers" ? "activeMenu" : ""
                    }
                  >
                    <Link to="/customers">
                      <CustomersIcon />
                      <span>Customers</span>
                    </Link>
                  </li>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>Notifications</Tooltip>}
                  delay={{ show: 250, hide: 400 }}
                >
                  <li className="position-relative">
                    <Link onClick={showNotifications} className="w-100">
                      <NotificationsIcon />
                      <span>Notifications</span>
                      <Badge bg="danger" className="ms-auto">{ total }</Badge>
                    </Link>

                    {showNotificationsMenu && (
                      <Notifications
                        onHide={(e) => setShowNotificationsMenu(false)}
                      />
                    )}
                  </li>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>Add new quote</Tooltip>}
                  delay={{ show: 250, hide: 400 }}
                >
                  <li
                    className={
                      location.pathname.startsWith("/quotes") ? "activeMenu" : ""
                    }
                  >
                    <Link to="/quotes/new" className="add-text">
                      <QuoteIcon />
                      <span>Add new quote</span>
                    </Link>
                  </li>
                </OverlayTrigger>
              </>
            )}
          </>
        ) : (
          <>
            <li className={location.pathname === "/" ? "activeMenu" : ""}>
              <Link to="/">
                <DashboardIcon />
                <span>Dashboard</span>
              </Link>
            </li>

            { isAdmin ? (
              <>
                <li
                  className={location.pathname === "/dealers" ? "activeMenu" : ""}
                >
                  <Link to="/dealers">
                    <DealersIcon />
                    <span>Dealers</span>
                  </Link>
                </li>
                <li
                  className={location.pathname === "/policies" ? "activeMenu" : ""}
                >
                  <Link to="/policies">
                    <PoliciesIcon />
                    <span>Policies</span>
                  </Link>
                </li>
                <li
                  className={location.pathname === "/compliance" ? "activeMenu" : ""}
                >
                  <Link to="/compliance">
                    <LendersIcon />
                    <span>Compliance</span>
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li
                  className={location.pathname === "/lenders" ? "activeMenu" : ""}
                >
                  <Link to="/lenders">
                    <LendersIcon />
                    <span>Lenders</span>
                  </Link>
                </li>

                <li
                  className={location.pathname === "/customers" ? "activeMenu" : ""}
                >
                  <Link to="/customers">
                    <CustomersIcon />
                    <span>Customers</span>
                  </Link>
                </li>

                <li className="position-relative">
                  <Link onClick={showNotifications} className="w-100">
                    <NotificationsIcon />
                    <span>Notifications</span>
                    <Badge bg="danger" className="ms-auto">{ total }</Badge>
                  </Link>

                  {showNotificationsMenu && (
                    <Notifications
                      onHide={(e) => setShowNotificationsMenu(false)}
                      onClear={props.onNotificationsClear}
                    />
                  )}
                </li>

                <li
                  className={
                    location.pathname.startsWith("/quotes") ? "activeMenu" : ""
                  }
                >
                  <Link to="/quotes/new" className="add-text">
                    <QuoteIcon />
                    <span>Add new quote</span>
                  </Link>
                </li>
              </>
            )}
          </>
        )}
      </ul>

      <ul>
        <li className="close-menu">
          <a href="/" onClick={props.toggle}>
            <CloseIcon />
            <span>Close menu</span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
