import React from "react";
import { Modal } from "react-bootstrap";

const PCPModal = (props) => {
  return (
    <>
      <Modal
        centered
        show={props.show}
        onHide={props.onHide}
        className="product-info-modal blurred"
      >
        <Modal.Header closeButton>
          PCP Finance Explained
        </Modal.Header>
        <Modal.Body>
          <h3 className="info-title">What is PCP?</h3>

          <p>
            A Personal Contract Purchase (PCP) can help customer’s buy a car with
            lower monthly repayments than a Hire Purchase (HP) product as a large
            proportion of the amount customers repay is deferred into an optional
            final repayment.  Customers pay more interest on a PCP agreement than a
            HP agreement for the same loan amount, term and APR as the loan balance
            reduces more slowly due to the optional final repayment.
          </p>

          <h3 className="info-title">How Personal Contract Purchase works</h3>

          <p>* You will set an initial deposit and term within the Fastcheck platform and set how many miles you will travel each year.</p>
          <p>* The Fastcheck platform will then work out the monthly repayment and the optional final repayment amount, based on the anticipated value of the vehicle at the end of the agreement.</p>
          <p>* You can adjust the deposit, term and mileage parameter to see what works best for your circumstances.</p>
          <p>* Once the PCP agreement has been entered into and the you have reached the end of the term, you can part exchange the vehicle, return the vehicle or pay the optional final repayment and own the vehicle.</p>
          <p>* In a PCP the lender buys the car on the your behalf and owns it for the duration of the finance agreement.</p>
          <p>* After all repayments have been made including the optional final repayment and any interest, you will become the owner of the vehicle.</p>

          <h3 className="info-title">What are your options at the end of a PCP?</h3>

          <p>
            * Part Exchange: Subject to the you paying off the existing agreement in full, you can part exchange the vehicle at the end of the term or any time during the agreement. New credit agreements are subject to status.
            The vehicle could be worth less that the optional final repayment leaving you with a shortfall to pay before starting a new agreement.
          </p>
          <p>* Return: You will also have the option to return the vehicle at the end of the term of the agreement. To avoid incurring charges, the vehicle needs to be in good condition and within the permitted maximum mileage.</p>
          <p>* Own: You can also own the vehicle outright by paying the optional final repayment. There is usually a nominal purchase fee of approx. £10 usually charged by the lender.</p>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default PCPModal;
