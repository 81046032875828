import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import moment from "moment";
import * as yup from "yup";

import { getErrorLabels } from "helpers/errors";
import Error from "components/Error";

const Legals = (props) => {
  const [ checkboxes, setCheckboxes ] = useState({});
  const [ errors, setErrors ] = useState({});

  const validationSchema = yup.object({
    acceptTAndCDate: yup.boolean().required(),
    acceptPrivacyPolicy: yup.boolean().required(),
    acceptInitialDisclosure: yup.boolean().required(),
  });

  const handleInputChange = (e) => {
    setCheckboxes({
      ...checkboxes,
      [e.target.name]: e.target.value,
    });
  }

  const handleSaveSection = (e) => {
    validationSchema.validate(checkboxes, { abortEarly: false })
      .then(() => {
        setErrors({});
        props.onUpdate({
          acceptTAndCDate: moment().format("YYYY-MM-DD HH:mm:ss"),
          acceptPrivacyPolicy: moment().format("YYYY-MM-DD HH:mm:ss"),
          acceptInitialDisclosure: moment().format("YYYY-MM-DD HH:mm:ss"),
        });
        props.setActiveTab("vehicle");
      })
      .catch(err => {
        setErrors(getErrorLabels(err));
      });
  }

  return (
    <div className="customer-details">
      <Row>
        <Col xs={12}>
          <label>Can you confirm the customer has seen the T&C’s and agrees to them?</label>
          <div>
            { props?.application?.applicationGuid ? (
              <>{ moment(props.application?.acceptTAndCDate).format("DD-MMM-YY HH:mm") }</>
            ) : (
              <>
                <Form.Check
                  name="acceptTAndCDate"
                  inline
                  value={true}
                  label="I confirm"
                  onChange={handleInputChange}
                />
                <Error name="acceptTAndCDate" errors={errors} />
              </>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <label>Can you confirm the customer has seen the Privacy Policy and agrees to them?</label>
          <div>
            { props?.application?.applicationGuid ? (
              <>{ moment(props.application?.acceptPrivacyPolicy).format("DD-MMM-YY HH:mm") }</>
            ) : (
              <>
                <Form.Check
                  name="acceptPrivacyPolicy"
                  inline
                  value={true}
                  label="I confirm"
                  onChange={handleInputChange}
                />
                <Error name="acceptPrivacyPolicy" errors={errors} />
              </>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <label>Can you confirm the customer has seen the Initial Disclosure and agrees to them?</label>
          <div>
            { props.application?.applicationGuid ? (
              <>{ moment(props.application?.acceptInitialDisclosure).format("DD-MMM-YY HH:mm") }</>
            ) : (
              <>
                <Form.Check
                  name="acceptInitialDisclosure"
                  inline
                  value={true}
                  label="I confirm"
                  onChange={handleInputChange}
                />
                <Error name="acceptInitialDisclosure" errors={errors} />
              </>
            )}
          </div>
        </Col>
      </Row>

      { !props.application?.applicationGuid && (
        <Row className="mb-0">
          <Col xs={12} className="d-flex justify-content-end">
            <button
              className="dark focus"
              onClick={handleSaveSection}
            >
              Save customer & continue
            </button>
          </Col>
        </Row>
      )}
    </div>    
  );
}

export default Legals;
