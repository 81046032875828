import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authTypes from "store/types/auth";

const initialState = {
  user: null,
  token: null,
  expiry: null,
  isAdmin: null,
  dealerGuid: null,
  notifications: null,
  loading: false,
  loadingMsg: null,
}

export const authReducer = persistReducer(
  { storage, key: "auth", whitelist: ["token", "expiry", "isAdmin", "dealerGuid"] },
  (state = initialState, { type, payload }) => {
    switch (type) {
      case authTypes.LOGIN_SUCCESSFUL:
        return {
          ...state,
          token: payload.token,
          expiry: payload.expiryDate,
          isAdmin: payload.admin,
          dealerGuid: payload.dealerGuid,
          notifications: null,
        };

      case authTypes.LOGOUT:
        return {
          ...state,
          token: null,
          expiry: null,
          isAdmin: null,
          notifications: null,
        };

      case authTypes.SET_NOTIFICATIONS:
        return {
          ...state,
          notifications: payload,
        };

      case authTypes.SET_LOADING:
        return {
          ...state,
          loading: payload,
        };

      case authTypes.SET_LOADING_MSG:
        return {
          ...state,
          loadingMsg: payload,
        };

      default:
        return state;
    }
  }
);
