import React, { useEffect, useState } from "react";
import { Accordion, Col, Form, Modal, Row } from "react-bootstrap";
import * as yup from "yup";

import { formatCurrency } from "helpers/currency";
import { getErrorLabels } from "helpers/errors";
import { getLenderQuestions, updateBankingDetails } from "services";
import Error from "components/Error";
import LenderLogo from "components/LenderLogo";

const AffordabilityStep = (props) => {
  const [ checkboxData, setCheckboxData ] = useState({
    dependants: "",
  });
  const [ errors, setErrors ] = useState({});
  const [ lenderQuestions, setLenderQuestions ] = useState([]);
  const [ lenderQuestionAnswers, setLenderQuestionAnswers ] = useState({});
  const [ lenderQuestionSchema, setLenderQuestionSchema ] = useState({});

  const validationSchema = yup.object({
    circumstances: yup.number().min(0).max(1).required(),
    dependants: yup.number().required(),
    repayments: yup.number().min(0).max(1).required(),
    productInfo: yup.number().min(0).max(1).required(),
  });

  useEffect(() => {
    getLenderQuestions(props.quote.quoteGuid)
      .then(res => {
        setLenderQuestions(res);

        let questionSchema = {};
        res.map(q => {
          questionSchema[q.questionGuid] = yup.string().required();
        });

        setLenderQuestionSchema(yup.object(questionSchema));
      });
  }, []);

  const isDisabled = () => {
    return !(parseInt(checkboxData?.repayments) && parseInt(checkboxData?.productInfo));
  }

  const handleInputChange = (e) => {
    setCheckboxData({
      ...checkboxData,
      [e.target.name]: e.target.value,
    });
  }

  const handleLenderQuestionsChange = (e) => {
    setLenderQuestionAnswers({
      ...lenderQuestionAnswers,
      [e.target.name]: e.target.value,
    });
  }

  const formatLenderQuestions = () => {
    let lenderQs = [];
    Object.keys(lenderQuestionAnswers).map(key => {
      lenderQs.push({
        questionGuid: key,
        answerValue: lenderQuestionAnswers[key],
      });
    });

    return lenderQs;
  }

  const handleProceed = () => {
    validationSchema.concat(lenderQuestionSchema)
      .validate({ ...checkboxData, ...lenderQuestionAnswers }, { abortEarly: false })
      .then(() => {
        let formattedLenderQuestions = {};

        if (lenderQuestions.length > 0) {
          formattedLenderQuestions = {
            lenderQuestions: formatLenderQuestions(),
          };
        }

        props.onProceed({
          curcumstancesChange: parseInt(checkboxData?.circumstances) === 1,
          numberOfDependants: parseInt(checkboxData?.dependants),
          replacementLoan: false,
          ...formattedLenderQuestions,
        });
      })
      .catch(err => {
        setErrors(getErrorLabels(err));
      });
  }

  return (
    <>
      <Modal.Header closeButton>
        Submit application to lender
      </Modal.Header>

      <Modal.Body className="submit-to-lender">
        <p className="info">We need more information from the customer to submit this quote to the lender.</p>

        <h3 className="section-title mt-4">Customer's affordability</h3>

        <Row>
          <Col xs={12}>
            <label className="small">Does the customer think their circumstances are likely to change at all during the term of their finance agreement?</label>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={12}>
            <Form.Check
              type="radio"
              name="circumstances"
              inline
              value={1}
              label="Yes"
              onChange={handleInputChange}
            />

            <Form.Check
              type="radio"
              name="circumstances"
              inline
              value={0}
              label="No"
              onChange={handleInputChange}
            />

            <Error name="circumstances" errors={errors} />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col xs={12}>
            <label className="small">How many people depend on the customer financially?</label>

            <Form.Select
              name="dependants"
              onChange={handleInputChange}
              style={{ width: "auto" }}
            >
              <option value=""></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
              <option value={9}>9</option>
              <option value={10}>10</option>
            </Form.Select>

            <Error name="dependants" errors={errors} />
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <label className="small">
              Does the customer believe that they will be able to comfortably afford the 
              repayments (£{ formatCurrency(props.quote?.payments) }pm) for the entire duration of the term of the finance agreement?
            </label>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={12}>
            <Form.Check
              type="radio"
              name="repayments"
              inline
              value={1}
              label="Yes"
              onChange={handleInputChange}
            />

            <Form.Check
              type="radio"
              name="repayments"
              inline
              value={0}
              label="No"
              onChange={handleInputChange}
            />

            <Error name="repayments" errors={errors} />
          </Col>
        </Row>

        { lenderQuestions.length > 0 && (
          <>
            { lenderQuestions.map(question => (
              <Row key={question.questionGuid}>
                <Col xs={12}>
                  <label>{ question.lenderSpecificQuestion }</label>
                  <input type="text" name={question.questionGuid} onChange={handleLenderQuestionsChange} />
                  <Error name={question.questionGuid} errors={errors} />
                </Col>
              </Row>
            ))}
          </>
        )}

        <Row>
          <Col xs={12}>
            <label className="small">
              Has the customer read the finance product information?
            </label>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={12}>
            <Form.Check
              type="radio"
              name="productInfo"
              inline
              value={1}
              label="Yes"
              onChange={handleInputChange}
            />

            <Form.Check
              type="radio"
              name="productInfo"
              inline
              value={0}
              label="No"
              onChange={handleInputChange}
            />

            <Error name="productInfo" errors={errors} />
          </Col>
        </Row>

        <p className="info">By submitting the application for finance the customer agrees and consents to the lender conducting a hard credit search on their details.</p>
      </Modal.Body>

      <Modal.Footer className="justify-content-between">
        <button
          onClick={props.onBack}
          className="outline"
        >
          Go back
        </button>
        <button
          onClick={handleProceed}
          disabled={isDisabled()}
          className="dark"
        >
          Submit to lender
        </button>
      </Modal.Footer>
    </>
  );
}

export default AffordabilityStep;
