import React from "react";
import { Modal, Spinner } from "react-bootstrap";

const WarningModal = (props) => {
  return (
    <Modal show={props.show} onHide={props.onCancel} className="confirm-modal">
      <Modal.Header closeButton>
        <Modal.Title>Warning</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { props.text ?? "Warning" }
      </Modal.Body>
      <Modal.Footer>
        <button
          className="dark"
          onClick={props.onCancel}
        >
          OK
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default WarningModal;
